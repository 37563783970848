<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2020-09-07 14:45:05.414 -->
<!-- @desc generated by yqg-cli@1.2.9 -->

<template>
    <div class="webview-operator">
        <operator
            color="#ff8361"
            agreement-key="INDIA_OPERATOR_SERVICE"
            :mobile-api="Biz.indiaMobile"
        />
    </div>
</template>

<script type="text/babel">
import operator from 'easycash/app/webview/spider/operator';
import Biz from 'easycash/common/resource/biz';

export default {
    name: 'Operator',

    components: {
        operator
    },

    data() {
        return {
            Biz
        };
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .webview-operator {
        padding: 0;
    }
</style>
