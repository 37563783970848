/*
 * @Author: chengyuzhang
 * @Date: 2020-09-07 13:39:58
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2020-10-15 14:59:16
 */

export default {
    AIRTEL: /^\d{4}$/,
    MYVODAFONE: /^\d{4}$/,
    MYJIO: /^\d{6}$/
};
