<!-- @Author: chengyuzhang -->
<!-- @Date: 2020-06-05 11:38:23.521 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2024-04-12 19:26:24 -->

<template>
    <div class="operator">
        <header>
            <svg
                t="1591335104594"
                class="security-icon"
                viewBox="0 0 1024 1024"
            >
                <!-- eslint-disable-next-line max-len -->
                <path
                    d="M161.408 120.576L512 42.666667l350.592 77.909333a42.666667 42.666667 0 0 1 33.408 41.642667v426.112a256 256 0 0 1-114.005333 212.992L512 981.333333l-269.994667-180.010666A256 256 0 0 1 128 588.373333V162.218667a42.666667 42.666667 0 0 1 33.408-41.642667zM469.333333 426.666667H341.333333v85.333333h128v128h85.333334v-128h128v-85.333333h-128V298.666667h-85.333334v128z"
                    p-id="9888"
                    :fill="color"
                />
            </svg>
            <span>{{ $t('webview.operator.title') }}</span>
        </header>

        <div class="operator-input">
            <div class="input-wrapper">
                <svg
                    class="phone-icon"
                    viewBox="0 0 1024 1024"
                >
                    <!-- eslint-disable-next-line max-len -->
                    <path
                        d="M170.666667 0h682.666666v1024H170.666667V0z m42.666666 42.666667v938.666666h597.333334V42.666667H213.333333z m298.666667 853.333333a64 64 0 1 0 0-128 64 64 0 0 0 0 128z"
                        :fill="color"
                    />
                </svg>
                <div class="input-content">
                    <input
                        v-model="mobileNumber"
                        type="text"
                        disabled
                        :class="{error: errorMsg.phone}"
                        @blur="validate"
                    >
                </div>
                <button
                    :style="{background: color}"
                    :disabled="codeButton.disabled"
                    :class="{disabled: codeButton.disabled}"
                    @click="clickCode"
                >
                    {{ codeButton.text }}
                </button>
            </div>
            <div
                v-show="errorMsg.phone"
                class="error-message"
            >
                {{ errorMsg.phone }}
            </div>
        </div>
        <div class="operator-input">
            <div class="input-wrapper">
                <svg
                    class="icon"
                    viewBox="0 0 1024 1024"
                >
                    <!-- eslint-disable-next-line max-len -->
                    <path
                        d="M883.754667 279.381333l-362.666667-170.666666a21.034667 21.034667 0 0 0-18.176 0l-362.666667 170.666666a21.312 21.312 0 0 0-12.245333 19.306667c0 475.776 346.602667 618.666667 384 618.666667s384-142.890667 384-618.666667a21.312 21.312 0 0 0-12.245333-19.306667zM513.578667 874.752c-3.392-0.490667-336.341333-106.666667-342.826667-562.56L512 151.573333l341.226667 160.576c-6.464 455.914667-339.370667 562.090667-339.626667 562.581334z"
                        :fill="color"
                    />
                    <!-- eslint-disable-next-line max-len -->
                    <path
                        d="M420.416 475.605333a21.333333 21.333333 0 0 0-30.165333 30.165334l85.333333 85.333333A21.354667 21.354667 0 0 0 490.666667 597.333333h0.704a21.376 21.376 0 0 0 15.36-7.296l149.333333-170.666666a21.333333 21.333333 0 0 0-32.128-28.096l-134.314667 153.514666z"
                        :fill="color"
                    />
                </svg>
                <textarea
                    v-if="isTelkomsel"
                    v-model="smsCode"
                    rows="4"
                    :class="{error: errorMsg.code}"
                    :placeholder="$t('webview.operator.textarea')"
                    @input="codeChange"
                />
                <input
                    v-else
                    v-model="smsCode"
                    type="text"
                    :class="{error: errorMsg.code}"
                    :placeholder="$t('webview.operator.verification')"
                    @input="codeChange"
                >
            </div>
            <div
                v-show="errorMsg.code"
                class="error-message"
            >
                {{ errorMsg.code }}
            </div>
        </div>

        <div
            v-if="isTelkomsel"
            class="code-notice"
        >
            * {{ $t('webview.operator.codeNotice') }}
        </div>

        <div class="agreement-confirm">
            <svg
                v-if="confirm"
                viewBox="0 0 1024 1024"
                @click="confirm = !confirm"
            >
                <!-- eslint-disable-next-line max-len -->
                <path
                    d="M512 512m-501.970824 0a501.970824 501.970824 0 1 0 1003.941648 0 501.970824 501.970824 0 1 0-1003.941648 0Z"
                    :fill="color"
                />
                <!-- eslint-disable-next-line max-len -->
                <path
                    d="M726.437647 365.598118h78.366118L454.354824 740.201412l-193.355295-193.355294h89.630118a30.117647 30.117647 0 0 1 18.462118 6.324706l85.263059 66.16847 251.030588-245.157647a30.117647 30.117647 0 0 1 21.052235-8.583529z"
                    fill="#fff"
                />
            </svg>

            <svg
                v-if="!confirm"
                viewBox="0 0 1024 1024"
                @click="confirm = !confirm"
            >
                <!-- eslint-disable-next-line max-len -->
                <path
                    d="M512 512m-501.970824 0a501.970824 501.970824 0 1 0 1003.941648 0 501.970824 501.970824 0 1 0-1003.941648 0Z"
                    fill="#ccc"
                />
                <!-- eslint-disable-next-line max-len -->
                <path
                    d="M726.437647 365.598118h78.366118L454.354824 740.201412l-193.355295-193.355294h89.630118a30.117647 30.117647 0 0 1 18.462118 6.324706l85.263059 66.16847 251.030588-245.157647a30.117647 30.117647 0 0 1 21.052235-8.583529z"
                    fill="#FFFFFF"
                />
            </svg>
            {{ $t('webview.operator.agreement') }}
            <router-link
                class="agreement-link"
                :style="{color}"
                :to="{name: 'staticAgreement', params: {key: agreementKey}}"
            >
                &nbsp;{{ $t('webview.operator.agreementLink') }}
            </router-link>
        </div>

        <button
            class="submit"
            :disabled="submitDisabled"
            :class="{disabled: submitDisabled}"
            :style="{background: color}"
            @click="clickSubmit"
        >
            {{ $t('webview.operator.next') }}
        </button>

        <ec-webview-loading-circle v-if="isLoading" />
    </div>
</template>

<script type="text/babel">
import Interface from 'ssr-common/util/webview/yqg-webview-interface';

import EcWebviewLoadingCircle from 'easycash/common/component/ec-webview-loading-circle';
import Biz from 'easycash/common/resource/biz';

import validateMap from './constant/validate';

export default {
    name: 'Operator',

    components: {
        EcWebviewLoadingCircle
    },

    props: {
        color: {
            type: String,
            default: '#0182e8'
        },

        agreementKey: {
            type: String,
            default: 'OPERATOR_SERVICE'
        },

        mobileApi: {
            type: Function,
            default: Biz.mobile
        }
    },

    data() {
        return {
            mobileNumber: this.$route.query.mobileNumber,
            smsCode: '',
            errorMsg: {
                phone: '',
                code: ''
            },
            confirm: false,
            isLoading: false,
            countdown: 0,
            taskId: ''
        };
    },

    computed: {
        codeButton() {
            const {mobileNumber, countdown, validate} = this;
            const disabled = !(validate(mobileNumber) && countdown === 0);
            const resendText = this.$t('webview.operator.resend');
            const text = countdown ? `${resendText} (${countdown}s)` : this.$t('webview.operator.verification');

            return {
                disabled,
                text
            };
        },

        submitDisabled() {
            const {mobileNumber, smsCode, confirm, validateCode} = this;

            return !mobileNumber || !smsCode || !confirm || !validateCode();
        },

        operatorType() {
            return this.$route.query.operatorType || '';
        },

        isTelkomsel() {
            return this.operatorType.toLocaleUpperCase() === 'TELKOMSEL';
        }
    },

    methods: {
        async clickCode() {
            if (this.countdown) return;

            try {
                if (this.taskId) {
                    await this.postSmsCode(true);
                } else {
                    await this.postMobile();
                }

                this.countdown = 60;
                const timmer = window.setInterval(() => {
                    if (this.countdown === 0) {
                        window.clearInterval(timmer);

                        return;
                    }

                    this.countdown -= 1;
                }, 1000);
            } catch (errorMsg) {
                this.errorMsg.phone = errorMsg || this.$t('webview.npwp.tryAgain');
            }
        },

        async clickSubmit() {
            try {
                if (!this.taskId) {
                    this.errorMsg.phone = this.$t('webview.operator.noVerification');

                    return;
                }

                if (!this.confirm) {
                    this.errorMsg.code = this.$t('webview.operator.noAgree');

                    return;
                }

                await this.postSmsCode();
            } catch (errorMsg) {
                this.errorMsg.code = errorMsg || this.$t('webview.npwp.tryAgain');
            }
        },

        async postMobile() {
            const {mobileNumber, operatorType} = this;
            const params = {
                mobileNumber,
                operatorType
            };
            this.errorMsg.phone = '';
            this.errorMsg.code = '';

            try {
                this.isLoading = true;
                const {data: {body, status}} = await this.mobileApi(params, {hideLoading: true, hideToast: true});

                if (status.code !== 0) {
                    return Promise.reject(status.detail);
                }

                this.taskId = body.taskId;

                return Promise.resolve();
            } catch (error) {
                return Promise.reject();
            } finally {
                this.isLoading = false;
            }
        },

        async postSmsCode(resendSms = false) {
            const {taskId, smsCode} = this;
            const params = {
                taskId,
                smsCode,
                resendSms
            };
            this.errorMsg.phone = '';
            this.errorMsg.code = '';

            try {
                this.isLoading = true;
                const {data: {body, status: {detail, code}}}
                    = await Biz.continue(params, {hideLoading: true, hideToast: true});

                if (code === 0) {
                    const {require, msg} = body;
                    if (require && !resendSms) return Promise.reject(msg);
                } else {
                    return Promise.reject(detail);
                }

                if (!resendSms) Interface.callHandler('submitTaskId', taskId);

                return Promise.resolve();
            } catch (error) {
                return Promise.reject();
            } finally {
                this.isLoading = false;
            }
        },

        validate(phone) {
            return phone;
        },

        validateCode() {
            const {smsCode, operatorType} = this;
            const validate = validateMap[operatorType.toLocaleUpperCase()];
            if (validate) {
                return validate.test(smsCode);
            }

            return true;
        },

        codeChange() {
            this.errorMsg.code = '';
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.operator {
    padding: 0;
    background: #f5f5f5;
    min-height: 100vh;

    button {
        display: block;
        padding: 1vh 3vw;
        border: 0;
        color: #fff;
        white-space: nowrap;
        border-radius: 3px;

        &:focus {
            outline: none;
        }

        &.disabled {
            opacity: 0.5;
        }
    }

    header {
        display: flex;
        justify-content: center;
        padding: 4vh 0;
        border-bottom: 1px solid #f5f5f5;
        background: #fff;
        font-size: 14px;

        svg {
            width: 4vw;
            height: 4.8vw;
            margin-right: 1vw;
        }

        span {
            white-space: nowrap;
            color: #999;
        }
    }

    .operator-input {
        border-bottom: 1px solid #f5f5f5;
        padding: 2vh 6vw;
        width: 100%;
        font-size: 13px;
        background: #fff;
        box-sizing: border-box;

        .input-wrapper {
            display: flex;
            align-items: center;

            svg {
                width: 6vw;
                height: 6vw;
            }

            input {
                padding-left: 2vw;
                outline: none;
                width: 100%;
                border: 0;
                height: 5vh;
                min-height: 18px;
                font-size: 16px;
                color: #333;

                &:disabled {
                    background: #fff;
                    border: 0;
                }

                &.error {
                    color: red;
                }
            }

            textarea {
                width: 100%;
                border: 0;
                outline: 0;
                resize: none;
                box-sizing: border-box;

                &::placeholder {
                    line-height: 58px;
                }
            }

            .input-content {
                flex: 1;

                input {
                    width: 100%;
                    box-sizing: border-box;
                }
            }
        }

        .error-message {
            margin-top: 4px;
            padding-left: 8vw;
            color: red;
        }
    }

    .code-notice {
        margin-top: 3vh;
        padding: 0 6vw;
    }

    .agreement-confirm {
        display: flex;
        align-items: center;
        margin-top: 5vh;
        padding: 0 6vw;

        svg {
            width: 5.6vw;
            height: 5.6vw;
            margin-right: 3vw;
        }

        .agreement-link {
            color: #0182e8;
        }
    }

    .submit {
        margin: 5vh auto;
        width: 88vw;
        padding: 2vh 0;
        font-size: 16px;
    }
}
</style>
