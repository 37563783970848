/*
 * @Author: chengyuzhang
 * @Date: 2020-06-11 12:51:41
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2020-11-06 11:09:26
 */

import Resource from '@yqg/resource';

const api = {
    npwp: {
        url: '/biz/task/npwp',
        method: 'post'
    },

    bpjs: {
        url: '/biz/task/bpjs',
        method: 'post'
    },

    mobile: {
        url: '/biz/task/idn/mobile',
        method: 'post'
    },

    indiaMobile: {
        url: '/biz/task/india/mobile',
        method: 'post'
    },

    continue: {
        url: '/biz/mobileInfo/continue',
        method: 'post'
    },

    education: {
        url: '/biz/task/idn/education',
        method: 'post'
    }
};

export default Resource.create(api);
